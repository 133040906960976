import React from "react"

import {
  FacebookShareButton,
  LinkedinShareButton,
  PinterestShareButton,
  TwitterShareButton
} from "react-share";

import facebookIcon from "../../images/article/facebook-icon.svg"
import pinterestIcon from "../../images/article/pinterest-icon.svg"
import linkedinIcon from "../../images/article/linkedin-icon.svg"
import twitterIcon from "../../images/article/twitter-icon.svg"
import shareIcon from "../../images/article/share-icon.svg"

import "./shareSection.css"

export default function ShareSection({ postUri, title, description, image }) {

  const siteUrl = `https://blog.quantosobra.com.br${postUri}`

  function copyToClipboard() {
    navigator.clipboard.writeText(siteUrl);
  }

  return (
    <aside className="hidden xl:flex flex-col items-center gap-3 w-32 absolute left-32">

      <span className="text-black-300 font-semibold text-xs">Compartilhe</span>

      <FacebookShareButton url={siteUrl}>
        <div className="shareContent flex items-center justify-center w-9 h-9 border border-solid border-black-200 rounded-full">
          <img className="shareButtonIcon w-5 h-5 transition" src={facebookIcon} />
        </div>
      </FacebookShareButton>

      <PinterestShareButton url={siteUrl} media={image} description={description}>
        <div className="shareContent flex items-center justify-center w-9 h-9 border border-solid border-black-200 rounded-full">
          <img className="shareButtonIcon w-5 h-5 transition" src={pinterestIcon} />
        </div>
      </PinterestShareButton>

      <LinkedinShareButton title={title} summary={description} source={siteUrl} url={siteUrl}>
        <div className="shareContent flex items-center justify-center w-9 h-9 border border-solid border-black-200 rounded-full">
          <img className="shareButtonIcon w-5 h-5 transition" src={linkedinIcon} />
        </div>
      </LinkedinShareButton>

      <TwitterShareButton title={title} url={siteUrl}>
        <div className="shareContent flex items-center justify-center w-9 h-9 border border-solid border-black-200 rounded-full">
          <img className="shareButtonIcon w-5 h-5 transition" src={twitterIcon} />
        </div>
      </TwitterShareButton>

      <div onClick={copyToClipboard} className="shareContent flex items-center justify-center w-9 h-9 border border-solid border-black-200 rounded-full">
        <img className="shareButtonIcon w-5 h-5 transition" src={shareIcon} />
      </div>

    </aside>
  )
}