import React from "react"

import SimilarPosts from "./similarPosts"
import MostReadPosts from "./mostReadPosts"

function MorePosts({ postID, similarPosts }) {

  return (
    <section className="bg-black-100 pt-5 md:pt-12 pb-6">
      <div className="mx-auto max-w-xs md:max-w-screen-md lg:max-w-screen-lg">

        <h2 className="md:mx-auto mb-8 font-semibold text-black-800 text-2xl">Você também poderá gostar de:</h2>

        <SimilarPosts similarPosts={similarPosts} title="Artigos similares" />

        {/*<MostReadPosts postID={postID} title="Mais lidos" />*/}

      </div>
    </section>
  )
}

export default MorePosts