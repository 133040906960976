import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

function Banner({ featuredImage, post }) {
  let isHomePage = false

  const pageQuery = useStaticQuery(graphql`
    query BannerPost {
      allWpPost(
        filter: {categories: {nodes: {elemMatch: {id: {eq: "dGVybToxOTgy"}}}}}
        limit: 1
      ){
        nodes {
          title
          uri
          featuredImage {
            node {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 520,
                    placeholder: BLURRED,
                    formats: [WEBP],
                    quality: 100
                  )
                }
              }
            }
          }
        }
      }
    }
  `)

  const bannercolor = `#${post.bannerColor?.bannercolor}` || "#7ABA7A"

  if (post === undefined) {
    isHomePage = true
    post = pageQuery.allWpPost.nodes[0]
    featuredImage = {
      image: getImage(post.featuredImage?.node.localFile),
      alt: post.featuredImage.node.altText,
    }
  }

  return (
    <section className="flex items-center justify-center mb-6 py-10 bg-greenQS" style={{backgroundColor: bannercolor}}>

      <GatsbyImage image={featuredImage.image} alt={featuredImage.alt} className="w-3/4 max-w-sm rounded-full mb-5" />

    </section>
  )
}

export default Banner