import React from "react"
import { Link } from "gatsby"
import parse from "html-react-parser"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

function UnderArticleCardPost({ post, templatePage }) {

  const title = post.title
  const category = {
    name: post.categories.nodes[0].name,
    uri: post.categories.nodes[0].uri
  }
  const featuredImage = {
    image: getImage(post.featuredImage?.node?.localFile),
    alt: post.featuredImage?.node?.alt || ``,
  }

  return (
    <li key={post.uri} className="relative w-56 mb-4">
      <Link to={post.uri} className="flex flex-col w-full gap-3">

        <GatsbyImage className="w-56 h-40" image={featuredImage.image} alt={featuredImage.alt} loading="lazy" />

        <header className="flex flex-col gap-3 w-full">

          <span className="text-xs text-black-600">{post.date}</span>

          <p className="text-xl font-bold text-left hover:text-greenQS transition overflow-ellipsis" itemProp="headline">
            {parse(title)}
          </p>

        </header>

      </Link>
    </li>
  )
}

export default UnderArticleCardPost