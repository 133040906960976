import React from "react"
import ReactMarkdown from 'react-markdown'

function MarkdownText({ children, className }) {

  return (
    <ReactMarkdown className={className}>{children}</ReactMarkdown>
  )
}

export default MarkdownText