import React from "react"
import { graphql, Link } from "gatsby"
import parse from "html-react-parser"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { FacebookProvider, Comments } from 'react-facebook';

import Layout from "../components/newLayout/layout"
import Form from "../components/newLayout/form"
import Seo from "../components/seo"
import MorePosts from "../components/morePosts/morePosts"
import Banner from "../components/underHeader/banner"

import { ClockIcon } from '@heroicons/react/24/outline'

import "../css/article.css"
import AuthorBio from "../components/underHeader/authorBio"
import FinalComponent from "../components/article/finalComponent"
import ShareSection from "../components/article/shareSection";

const BlogPostTemplate = ({ data: { post, allWpPost, lateralComponentImage, finalComponentImage } }) => {

  const similarPosts = allWpPost.nodes

  const featuredImage = {
    image: getImage(post.featuredImage?.node?.localFile),
    alt: post.featuredImage?.node?.alt || ``,
  }

  const author = {
    name: post.author.node.name,
    avatar: post.author.node?.avatar?.url,
    description: post.author.node?.description,
    uri: post.author.node.name === "Stéfano Willig" ? "/autor/stefano" : post.author.node.uri
  }

  const lateralComponent = {
    title: post.componenteLateral?.tituloComponenteLateral || "Controle tudo no mesmo lugar e não pague nada por isso!",
    cta: post.componenteLateral?.ctacomponenteLateral || "Use o Awise",
    link: post.componenteLateral?.linkComponenteLateral || "https://www.useawise.com/?utm_source=QuantoSobra&utm_medium=blog&utm_campaign=post-cta-lateral",
    image: getImage(post.componenteLateral?.imagemComponenteLateral?.localFile) || getImage(lateralComponentImage)
  }

  const finalComponent = {
    title: post.componenteFinalArtigo?.titulocomponentefinalartigo || "Descomplique sua gestão e deixe sua loja mais organizada.",
    desc: post.componenteFinalArtigo?.descricaocomponentefinalartigo || "Feito para todos os tamanhos de loja, o Awise é de graça e ajuda você a ter o controle de tudo.",
    cta: post.componenteFinalArtigo?.ctacomponentefinalartigo || "Clique e comece a usar!",
    link: post.componenteFinalArtigo?.linkcomponentefinalartigo || "https://www.useawise.com/?utm_source=QuantoSobra&utm_medium=blog&utm_campaign=post-cta-final",
    image: getImage(post.componenteFinalArtigo?.imagemcomponentefinalartigo?.localFile) || getImage(finalComponentImage)
  }

  let formattedContent = parse(post.content.replaceAll("hidden", "visible"))
  let parsedDiv = parse(`<div class="summary"><p>-</p><p>-</p></div>`)

  if (parse(post.content)[0].props?.children?.props?.children === "Neste artigo você vai ler:" ||
  parse(post.content)[0].props?.children === "Neste artigo você vai ler:") {

    parsedDiv.props.children.push(formattedContent[0])
    if (formattedContent[1] === "\n") formattedContent.shift()
    parsedDiv.props.children.push(formattedContent[1])

    parsedDiv.props.children.shift()
    parsedDiv.props.children.shift()

    formattedContent.shift()
    formattedContent.shift()
    formattedContent.unshift(parsedDiv)
  }

  let time = "6"
  const text = post.content;
  const wpm = 225;
  const words = text.trim().split(/\s+/).length;
  time = Math.ceil(words / wpm);

  return (
    <Layout>

      <Seo title={post.title} description={post.excerpt} image={post.featuredImage?.node.localFile.url} />

      <article className="mt-32 lg:mt-36">

        <header className="mb-3 mx-auto max-w-xs md:max-w-screen-md">

          <h1 className="text-4xl text-textQS font-bold leading-normal mb-12 md:mb-3">{post.title}</h1>

          <div className="flex flex-col lg:flex-row lg:items-center lg:justify-between gap-3 text-sm md:text-base text-black-600">
            <div className="flex flex-col lg:flex-row lg:items-center gap-3">
              <Link to={author.uri} className="flex items-center gap-3 md:gap-5">
                <img className="w-10 h-10 rounded-full border-gray shadow" src={author.avatar} alt={author.firstName} />
                <span className="text-middleGreen">{author.name}</span>
              </Link>
              <div className="flex flex-row gap-3 lg:mt-px">
                <span>3 de dezembro de 2021</span>
                <span>editado 06/12/2021</span>
              </div>
            </div>
            <span className="flex flex-row gap-1"><ClockIcon className="h-5 w-5 text-gray" />{time} mins de leitura</span>
          </div>

        </header>

        <Banner featuredImage={featuredImage} post={post} />

        <section className="relative flex mx-auto max-w-xs md:max-w-screen-md xl:max-w-screen-xl" itemProp="articleBody">

          <ShareSection postUri={post.uri} title={post.title} description={post.excerpt} image={post.featuredImage?.node.localFile.url} />

          <div className="postContent mx-auto py-5 font-html leading-relaxed">
            {formattedContent}
          </div>

          <aside className="hidden xl:flex flex-col w-32 justify-center absolute right-24">
            <GatsbyImage className="w-full h-full mb-3" image={lateralComponent.image} alt="homem carregando carrinho" />
            <p className="mb-3 font-bold text-sm">{lateralComponent.title}</p>
            <a className="flex items-center justify-center h-5 bg-orange rounded text-white font-semibold text-xs text-center" href={lateralComponent.link}>{lateralComponent.cta}</a>
          </aside>

        </section>

        <FinalComponent data={finalComponent} />

        <div className="flex flex-col mb-4 md:mb-12 mx-auto max-w-xs md:max-w-screen-md">
          <div className="flex flex-col md:flex-row gap-2">
            <span className="text-black-600 font-bold text-base">Categorias:</span>
            <ul className="flex flex-col md:flex-row mb-3 md:gap-5">
              {post.categories.nodes.map((item) => <li className="text-black-600 font-semibold text-base">{item.name}</li>)}
            </ul>
          </div>
          <div className="w-full h-px bg-black-200" />
        </div>

        <AuthorBio authorData={author} />

        <footer>

          <Form />

          <MorePosts postID={post.id} similarPosts={similarPosts} />

          <div className="facebookCommentsLayout my-12 mx-auto max-w-xs md:max-w-screen-md">

            <FacebookProvider appId="1335878897220689">
              <Comments width="768" href={`https://www.facebook.com/${post.uri}`} />
            </FacebookProvider>

          </div>

        </footer>

      </article>

    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostById(
    # these variables are passed in via createPage.pageContext in gatsby-node.js
    $id: String!
    $previousPostId: String
    $nextPostId: String
    $categoryId: String!
  )
  {
    # selecting the current post by id
    post: wpPost(id: { eq: $id }) {
      id
      uri
      excerpt
      content
      title
      date(formatString: "DD/MM/YYYY")

      bannerColor {
        bannercolor
      }

      author {
        node {
          name
          description
          uri
          avatar {
            url
          }
        }
      }

      categories {
        nodes {
          name
        }
      }

      componenteLateralDoArtigo {
        tituloComponenteLateral
        ctaComponenteLateral
        linkComponenteLateral
        imagemComponenteLateral {
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 230,
                placeholder: BLURRED,
                formats: [WEBP],
                quality: 100
              )
            }
          }
        }
      }

      componenteFinalArtigo {
        titulocomponentefinalartigo
        descricaocomponentefinalartigo
        ctacomponentefinalartigo
        linkcomponentefinalartigo
        imagemcomponentefinalartigo {
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 768,
                placeholder: BLURRED,
                formats: [WEBP],
                quality: 100
              )
            }
          }
        }
      }

      featuredImage {
        node {
          altText
          localFile {
            url
            childImageSharp {
              gatsbyImageData(
                width: 1000,
                placeholder: BLURRED,
                formats: [WEBP],
                quality: 100
              )
            }
          }
        }
      }
    }

    # this gets us the previous post by id (if it exists)
    previous: wpPost(id: { eq: $previousPostId }) {
      uri
      title
    }

    # this gets us the next post by id (if it exists)
    next: wpPost(id: { eq: $nextPostId }) {
      uri
      title
    }

    # for similar posts
    allWpPost(
      sort: { fields: [date], order: DESC }
      limit: 4
      filter: {
        categories: {nodes: {elemMatch: {id: {eq: $categoryId}}}},
        id: {ne: $id}
      }
    )
    {
      nodes {
        uri
        date(formatString: "DD/MM/YYYY")
        title
  
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 574,
                  placeholder: BLURRED,
                  formats: [WEBP],
                  quality: 100
                )
              }
            }
          }
        }

        categories {
          nodes {
            name
            uri
          }
        }
      }
    }

    lateralComponentImage: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "lateralComponentImage.webp"}) {
      childImageSharp {
        gatsbyImageData(
          width: 230,
          placeholder: BLURRED,
          formats: [WEBP],
          quality: 100
        )
      }
    }

    finalComponentImage: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "finalComponentImage.webp"}) {
      childImageSharp {
        gatsbyImageData(
          width: 230,
          placeholder: BLURRED,
          formats: [WEBP],
          quality: 100
        )
      }
    }
  }
`