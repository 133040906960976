import React from "react"
import { Link } from "gatsby"
import parse from "html-react-parser"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import MarkdownText from "../../components/markdownText"

import "./finalComponent.css"

export default function FinalComponent({ data }) {

  return (
    <div className="flex flex-col md:flex-row items-center gap-3 md:gap-16 mb-8 bg-green-100 py-7 mx-auto max-w-sm md:max-w-screen-md">
      <GatsbyImage className="w-28 md:w-44 md:ml-20 h-auto" image={data.image} alt="icone de cta" loading="lazy" />
      <div className="flex flex-col items-start max-w-xs md:max-w-sm">
        <MarkdownText className="finalComponentMarkdownStyle mb-3 text-black-800 font-bold md:text-2xl">{`### ${data.title}`}</MarkdownText>
        <p className="mb-6 md:mb-3 text-black-500 text-sm md:text-base">{data.desc}</p>
        <a className="flex items-center justify-center py-1 px-9 bg-green-700 border border-solid border-green-800 rounded-md text-white font-bold" href={data.link}>{data.cta}</a>
      </div>
    </div>
  )
}