import React from "react"

import UnderArticleCardPost from "../article/underArticleCardPost"

function SimilarPosts({ similarPosts }) {

  return (
    <ul className="md:mx-auto flex flex-row lg:justify-between gap-6 mb-0 overflow-x-auto snap-x">

      {similarPosts.map((post, i) => <UnderArticleCardPost key={i} post={post} />)}

    </ul>
  )
}

export default SimilarPosts